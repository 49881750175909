import React, { Component } from 'react';

export class Footer extends Component {
  render () {
    return (
        <footer className="footer">
            <div className="container">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                    <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
                        Copyright Onsign 2020
                </span>
                </div>
            </div>
        </footer>
    );
  }
}
