import React, { Component } from "react";
import { Footer } from "./Footer";
import { BoxSearch } from "./BoxSearch";
import { Link, Redirect } from "react-router-dom";
import { NavMenu } from "./NavMenu";
import { Projects } from "./Project";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            totalCount: 0,
            pagesCount: 0,
            pageCurrent: 1,
            itemPerPage: 20,
            username: null,
            projectId: "",
            textSearch: "",
            startDateShow: "",
            startDate: "",
            endDateShow: "",
            endDate: "",
        };
    }
    componentDidMount() {
        this.getDataLogs();
    }
    getDataLogs = async () => {
        var url = `api/logs?pageNumber=${this.state.pageCurrent}&itemPerPage=${this.state.itemPerPage
            }&p=${this.state.projectId}&t=${this.state.textSearch
            }&s=${this.state.startDate}&e=${this.state.endDate}`;
        const response = await fetch(url);

        const data = await response.json();
        if (data.username === null || data.username === '')
            document.location.href = "/login";
        else {
            this.setState({
                logs: data.logDataList,
                totalCount: data.totalCount,
                pagesCount: data.pagesCount,
                username: data.username
            });
        }
    };

    sortData = event => {
        var sort = event.target.value;

        const dataSort = this.state.logs;
        var newData = dataSort;
        if (sort === "0") {
            newData = dataSort.sort(
                (a, b) => new Date(a.dateCreatedOn) - new Date(b.dateCreatedOn)
            );
        } else {
            newData = dataSort.sort(
                (a, b) => new Date(b.dateCreatedOn) - new Date(a.dateCreatedOn)
            );
        }
        this.setState({ logs: newData });
    };

    changeItemPerpage = event => {
        var item = event.target.value;
        this.setState({ itemPerPage: item, pageCurrent: 1 }, function () {
            this.getDataLogs();
        });
    };

    btnAfterClick = () => {
        var curr = parseInt(this.state.pageCurrent) + 1;
        this.setState(
            {
                pageCurrent: curr > this.state.pagesCount ? this.state.pagesCount : curr
            },
            function () {
                this.getDataLogs();
            }
        );
    };

    btnLastClick = () => {
        this.setState(
            { pageCurrent: this.state.pagesCount },
            function () {
                this.getDataLogs();
            }
        );
    };

    btnHeadClick = () => {
        this.setState({ pageCurrent: 1 }, function () {
            this.getDataLogs();
        });
    };

    btnNextClick = () => {
        this.setState(
            { pageCurrent: this.state.pageCurrent - 1 },
            function () {
                this.getDataLogs();
            }
        );
    };
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.getDataLogs();
        }
    }

    handleNumberPage = e => {
        if (isFinite(e.target.value)) {
            this.setState({ pageCurrent: e.target.value });
        }
    };

    // Implement Search Code Search
    handleProjectChange = e => {
        this.setState({ projectId: e.target.value });
    };

    handleTextChange = e => {
        this.setState({ textSearch: e.target.value });
    };


    handleStartChange = e => {
        this.setState({ startDateShow: e });
        this.setState({ startDate: moment(e).format("YYYY-MM-DD") });
    };

    handleEndChange = e => {
        this.setState({ endDateShow: e });
        this.setState({ endDate: moment(e).format("YYYY-MM-DD") });
    };
    handleSearch = e => {
        this.setState({ pageCurrent: 1 }, () => {
            this.getDataLogs();
        });
    };
    render() {
        return (
            <div className="container-fluid page-body-wrapper">
                <NavMenu username={this.state.username} />
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="pb-0 card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group row">
                                            <div className="col-sm-3">
                                                <Projects onChange={this.handleProjectChange} />
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    onChange={this.handleTextChange}
                                                    className="form-control"
                                                    placeholder="Tìm kiếm tiêu đề, nội dung..."
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-custom"
                                                    onClick={this.handleSearch}
                                                >
                                                    {" "}
                        Tìm kiếm{" "}
                                                    {this.state.isLoading &&
                                                        <i className="fa fa-refresh fa-spin" />
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group row">
                                            <div className="col-sm-3 row">
                                                <div className="col-sm-6">
                                                    <DatePicker
                                                        className="form-control"
                                                        selected={this.state.startDateShow}
                                                        placeholderText="Select Date"
                                                        showPopperArrow={false}
                                                        onChange={this.handleStartChange}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <DatePicker
                                                        className="form-control"
                                                        selected={this.state.endDateShow}
                                                        placeholderText="Select Date"
                                                        showPopperArrow={false}
                                                        onChange={this.handleEndChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-panel">
                    <div className="content-wrapper pb-0">
                        <div className="row">
                            <div className="col-xl-12 stretch-card grid-margin">
                                <div className="card">
                                    <div className="card-body pb-0 pt-3">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h4 className="card-title">Danh sách lỗi</h4>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="bd-highlight">
                                                    <div className="d-flex bd-highlight justify-content-end">
                                                        <div className="bd-highlight align-self-center">
                                                            Hiển thị&nbsp;
                            </div>
                                                        <div className="bd-highlight">
                                                            <select
                                                                className="form-control form-control-sm"
                                                                value={this.state.itemPerPage}
                                                                onChange={this.changeItemPerpage}
                                                            >
                                                                <option value="10">10</option>
                                                                <option value="20">20</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>
                                                        <div className="bd-highlight align-self-center">
                                                            &nbsp;dòng
                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="d-flex bd-highlight flex-wrap">
                                                    <div className="bd-highlight pagination-area">
                                                        <button
                                                            className="btn btn-link btn-fw btn-link-custom"
                                                            disabled={this.state.pageCurrent === 1 ? "1" : ""}
                                                            onClick={this.btnHeadClick}
                                                        >
                                                            Đầu
                            </button>
                                                        <button
                                                            className="btn btn-link btn-fw btn-link-custom"
                                                            disabled={this.state.pageCurrent === 1 ? "1" : ""}
                                                            onClick={this.btnNextClick}
                                                        >
                                                            Trước
                            </button>
                                                        <label className="pagination-current">
                                                            <strong>
                                                                <input
                                                                    className="form-control form-control-sm paging-textbox"
                                                                    type="text"
                                                                    value={this.state.pageCurrent}
                                                                    onChange={this.handleNumberPage}
                                                                    onKeyDown={this.handleKeyDown}
                                                                />/{this.state.pagesCount}
                                                            </strong>
                                                        </label>
                                                        <button
                                                            className="btn btn-link btn-fw btn-link-custom"
                                                            disabled={
                                                                this.state.pageCurrent === this.state.pagesCount
                                                                    ? "1"
                                                                    : ""
                                                            }
                                                            onClick={this.btnAfterClick}
                                                        >
                                                            Sau
                            </button>
                                                        <button
                                                            className="btn btn-link btn-fw btn-link-custom"
                                                            disabled={
                                                                this.state.pageCurrent === this.state.pagesCount
                                                                    ? "1"
                                                                    : ""
                                                            }
                                                            onClick={this.btnLastClick}
                                                        >
                                                            Cuối
                            </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <select
                                                    className="form-control"
                                                    onChange={this.sortData}
                                                >
                                                    <option value="-1">Sắp xếp</option>
                                                    <option value="0">Thời gian tăng dần</option>
                                                    <option value="1">Thời gian giảm dần</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table custom-table text-dark">
                                                <thead>
                                                    <tr>
                                                        <th>Dự án</th>
                                                        <th>Tiêu đề</th>
                                                        <th>Nội dung</th>
                                                        <th>Thời gian</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.logs.map(item => (
                                                        <tr key={item.id.increment}>
                                                            <td>{item.project.projectName}</td>
                                                            <td>
                                                                <Link
                                                                    to={{
                                                                        pathname: `Detail/${item.idTemp}`,
                                                                        query: { id: item.idTemp }
                                                                    }}
                                                                >
                                                                    {item.title}
                                                                </Link>
                                                            </td>
                                                            <td>{item.content}</td>
                                                            <td>{item.dateCreatedShow}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}
