import React, { Component } from "react";
import { Redirect } from "react-router-dom";
export class NavMenu extends Component {
  constructor(props) {
      super(props);
      this.state = {
          status: 1
      }
    }
    handleLogout = async () => {
        var url = `api/user/logout`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({ status: 0 });
    };

  render() {
    if (!this.state.status) {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    return (
      <div className="horizontal-menu">
        <nav className="bottom-navbar">
          <div className="container">
            <ul className="nav page-navigation">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  <i className="mdi mdi-compass-outline menu-icon" />
                  <span className="menu-title">Dashboard</span>
                </a>
              </li>

              <li className="nav-item">
                <a href="#" className="nav-link">
                                <i className="mdi mdi-account menu-icon" />
                  <span className="menu-title">{this.props.username}</span>
                  <i className="menu-arrow" />
                </a>
                <div className="submenu">
                  <ul className="submenu-item">
                    <li className="nav-item">
                                        <button type="button" className="btn btn-link btn-fw" onClick={this.handleLogout}> Đăng xuất </button>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
