import React, { Component } from "react";
import {Redirect } from "react-router-dom";

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            user: null,
            error: ""
        };
    }
    handleUsernameChange = e => {
        this.setState({ username: e.target.value });
    };

    handlePasswordChange = e => {
        this.setState({ password: e.target.value });
    };

    handleLogin = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username: this.state.username, password: this.state.password })
        };
        const response = await fetch("api/user/login", requestOptions);
        const data = await response.json();
        this.setState({ user: data, error: "Thông tin đăng nhập không hợp lệ" });
    };

    render() {
        if (this.state.user && this.state.user.username) {
            return <Redirect to={{ pathname: "/" }} />;
        }
        return (
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row d-flex justify-content-center form-login">
                            <div className="col-md-3 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title text-center">Đăng nhập</h4>
                                        <p className="card-description text-danger">{ this.state.error}</p>
                                        <div className="form-group">
                                            <label>Tài khoản</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Tài khoản"
                                                onChange={this.handleUsernameChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Mật khẩu</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Mật khẩu"
                                                onChange={this.handlePasswordChange}
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary mr-2" onClick={this.handleLogin}>
                                            {" "}
                        Đăng nhập{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
