import React, { Component } from "react";

export class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = { project: [] };
  }
  componentDidMount() {
    this.getProjectData();
  }
  async getProjectData() {
    const response = await fetch("api/projects");
    const data = await response.json();
    this.setState({ project: data });
  }
  render() {
    return (
        <select className="form-control" onChange={this.props.onChange}>
        <option>Chọn dự án</option>
        {this.state.project.map(item => (
            <option key={item.projectID} value={item.projectID}>
            {item.projectName}
          </option>
        ))}
      </select>
    );
  }
}
