import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      log: null
    };
  }
  componentDidMount() {
    this.getDataLogs();
  }
  getDataLogs = async () => {
    const response = await fetch(`api/logs/${this.state.id}`);
    const data = await response.json();
    this.setState({ log: data });
  };
  render() {
    if (!this.state.log) {
      return null;
    }
      return (
          <div className="container-fluid page-body-wrapper mt-3">
        <table className="table custom-table text-dark">
                <tbody>
                    <tr>
                        <td>
                            <b>Dự án: </b>
                            {this.state.log.project.projectName}
                        </td>
                    </tr>
            <tr>
              <td>
                <b>Tiêu đề: </b>
                {this.state.log.title}
              </td>
            </tr>
            <tr>
              <td>
                <b>Nội dung: </b>
                {this.state.log.content}
              </td>
            </tr>
            <tr>
              <td>
                <b>Tên file: </b>
                {this.state.log.fileName}
              </td>
            </tr>
            <tr>
              <td>
                <b>Tên hàm: </b>
                {this.state.log.functionName}
              </td>
            </tr>

            <tr>
              <td>
                <b>Username: </b>
                {this.state.log.userName}
              </td>
            </tr>

            <tr>
              <td>
                <b>Địa chỉ ip: </b>
                {this.state.log.ip}
              </td>
            </tr>
          </tbody>
        </table>
            <Link to="/" className="mt-2 ml-4 btn btn-primary">Quay lại</Link>
      </div>
    );
  }
}
